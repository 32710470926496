import React, { Fragment, PureComponent } from 'react'
import {
  Jumbotron,
  ImageSm,
  Layout
} from '../components'
import { Link } from 'gatsby'
import './style.less'
// We can cater for
// larger bookings of up to 36 within the barn style main restaurant
class Home extends PureComponent {
  componentDidMount () {
    document.body.scrollTop = 0
  }

  render () {
    return (
      <Fragment>
        <Layout
          title={'Home'}
          location={this.props.location}
          keywords={[
            'The Woolpack',
            'Pubs near me',
            'food pubs near me',
            'dog friendly pubs kent',
            'places to eat in sittingbourne',
            'pubs sittingbourne',
            'restaurant sittingbourne'
          ]}
        >
          <Jumbotron
            heading={'The Woolpack Iwade Public House and Restaurant'}
            subText={
              'An age-old pub, The Woolpack promises comfort, character and great food and drink'
            }
            image={'home.jpg'}
            imageAlt={'Out side night time view of the woolpack'}
          />
          <div className='container'>

            <div className='row'>
              <div className='col-md-6 col-xs-12'>
                <h3 className='para-h2'>About us</h3>
                <p>
                  The Woolpack is a local village pub and restaurant located in
                  Iwade, Sittingbourne, Kent. We are dedicated to our work and
                  strive to provide a warm and welcoming environment. Our aim is
                  to ensure all our customers leave with a smile on their face and
                  want to come back again and again.
                  <br />
                  <br />
                Our staff, who are highly trained and motivated, along with
                Landlords Paul and Alison Hood who have over 35 years of
                experience in the hospitality industry, will make sure your
                visit is one to remember. Inside our pub, you will be greeted by
                traditional décor, leather seating, vintage features and open
                fires housed within our barn style main restaurant. The Woolpack
                pub is very excited to show off the history of its 300-year-old
                building, so why not head on down.
                </p>
              </div>
              <div className='col-md-6 col-xs-12' style={{ paddingTop: '30px' }}>
                <ImageSm image={'homeSub1.jpg'} />
              </div>
            </div>
            <div className='row'>
              <div className='col-md-6 col-xs-12'>
                <h3 className='para-h2'>What We Have To Offer</h3>
                <p>
                  The Woolpack are glad to offer a wide variety of options from
                  our selection of menus below: Pub Classics Selection, Main Menu
                  Dishes, Steak and Grill Selection, Black Rock Grill Menu,
                  Children&apos;s Menu, Special Boards, Sunday Roast Menu, Light
                  Bites Menu and Desserts Menu
                  <br />
                  <br />
                We are a dog-friendly pub, so if you have a dog and fancy a
                drink why not bring your dog down, dogs are permitted in the bar
                area only.
                  <br />
                  <br />
                Please phone in advance to book a table or an event, as at peak
                and seasonal times, we can become fully booked. We also offer a
                separate buffet menu for all events.
                  <br />
                </p>
              </div>
              <div className='col-md-6 col-xs-12' style={{ paddingTop: '30px' }}>
                <ImageSm image={'bar.jpg'} />
              </div>
            </div>
            <div className='row'>
              <div className='col-md-6 col-xs-12'>
                <h3 className='para-h2'>Our Facilities</h3>
                <p>
                  At The Woolpack, we have a lovely decked beer garden, with bench
                  seats and a lit paved area. If you are a smoker, we provide a
                  sheltered smoking area.
                  <br />
                Take a look at our bar and restaurant
                over in our <Link to='/Gallery'> Gallery </Link>
                </p>
              </div>
              <div className='col-md-6 col-xs-12'>
                <h3 className='para-h2'>Where can we find you?</h3>
                <p>
                  You can find The Woolpack Pub and Restaurant at 17 The Street
                  opposite All Saints Church.
                  <Link to='/Find-Us'>
                    Click here for more details on the Location.
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </Layout>
      </Fragment>
    )
  }
}

export default Home
